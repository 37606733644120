import React, { useState, useRef } from 'react';
import './FormComponent.css';

const FormComponent = () => {
  const [formData, setFormData] = useState({
    age: '',
    phoneNumber: '',
    region: '',
    agreedToTerms: false,
  });

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isEligible, setIsEligible] = useState(true);
  const [apiResponse, setApiResponse] = useState('');
  const [showSignUp, setShowSignUp] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [smsSent, setSmsSent] = useState(false); 
  const [agreedToStudyInfo, setAgreedToStudyInfo] = useState(false);
  const [canAgree, setCanAgree] = useState(false);

  const scrollRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleRegionChange = (e) => {
    setFormData({
      ...formData,
      region: e.target.value,
    });
  };

  const handlePhoneNumberChange = (e) => {
    const inputValue = e.target.value;

    // Allow only valid characters: a single leading "+" and digits
    if (inputValue === '' || inputValue.match(/^\+?[0-9]*$/)) {
      setFormData({
        ...formData,
        phoneNumber: inputValue,
      });
    }
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Check if the phone number starts with +41 or +49
    return phoneNumber.startsWith('+41') || phoneNumber.startsWith('+49') || phoneNumber.startsWith('0041') || phoneNumber.startsWith('0049');
  };

  const checkIfPhoneNumberExists = async (phoneNumber) => {
    if (phoneNumber.startsWith('0041')) {
      phoneNumber = '+41' + phoneNumber.substring(4);
    } else if (phoneNumber.startsWith('0049')) {
      phoneNumber = '+49' + phoneNumber.substring(4);
    }
    console.log('Phone number:', phoneNumber);
    // Make a request to send an SMS verification code
    const url_checkPhone = 'https://tap-study.ch:8080/api/checkIfPhoneNumberExists';
    const requestData = {
      phone: phoneNumber,
    };

    return fetch(url_checkPhone, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 1) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        return false;
      });

      console.log('Form data:', formData);
  };

  const validateEligibility = (age, region, phoneNumber) => {
    // Check if the user is eligible to participate in the study
    if (age < 16 || age > 25) {
      return false;
    }
    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(formData.phoneNumber)) {
      setErrorMessage('Bitte geben Sie eine gültige Telefonnummer ein, die mit +41 oder +49 beginnt.');
      return;
    }

    if (!(await checkIfPhoneNumberExists(formData.phoneNumber))) {
      setErrorMessage('Die Telefonnummer wurde bereits registriert.');
      return;
    }

    if (!validateEligibility(formData.age, formData.region, formData.phoneNumber)) {
      setApiResponse('Wir danken Ihnen für Ihr Interesse an der Studie. Leider sind Sie nicht berechtigt, an der Studie teilzunehmen, da wir nur Personen im Alter von 16 bis 25 Jahren in der Region Basel suchen.');
      setIsEligible(false);
      return;
    }

    /*if (!formData.agreedToTerms) {
      setErrorMessage('Sie müssen den Bedingungen zustimmen.');
      return;
    }*/

    // Clear the error message if everything is valid
    setErrorMessage('');

    let phoneNumber = formData.phoneNumber;

    if (formData.phoneNumber.startsWith('0041')) {
      phoneNumber = '+41' + phoneNumber.substring(4);
    } else if (phoneNumber.startsWith('0049')) {
      phoneNumber = '+49' + phoneNumber.substring(4);
    }

    // Make a request to send an SMS verification code
    const url_sms = 'https://tap-study.ch:8080/api/sendOTP';
    const requestData = {
      phone: phoneNumber,
    };

    fetch(url_sms, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 1) {
          setSmsSent(true);
          setApiResponse(response.message);
        } else {
          setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      });

    console.log('Form data:', formData);
  };

  const handleVerification = (e) => {
    e.preventDefault(); 

    if (formData.phoneNumber.startsWith('0041')) {
      formData.phoneNumber = '+41' + formData.phoneNumber.substring(4);
    } else if (formData.phoneNumber.startsWith('0049')) {
      formData.phoneNumber = '+49' + formData.phoneNumber.substring(4);
    }

    const url_signup = 'https://tap-study.ch:8080/api/submitSignUpForm';
    const requestData = {
      otp: verificationCode,
      region: formData.region,
      age: formData.age,
      phone: formData.phoneNumber,
    };

    fetch(url_signup, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === 1) {
          setApiResponse(response.message);
          setIsSubmitted(true);
        } else if (response.status === 0) {
          setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        } else if (response.status === 2) {
          setErrorMessage(response.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
      });

    console.log('Form data:', formData);
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      const element = scrollRef.current as HTMLElement;
      const scrollPosition = element.scrollTop + element.clientHeight;
      const threshold = 50; // Small buffer to account for rounding differences
      const bottom = element.scrollHeight - scrollPosition <= threshold;
  
      if (bottom) {
        setCanAgree(true);
      }
    }
  };

  const handleAgree = () => {
    setAgreedToStudyInfo(true);
    setShowSignUp(true);
  };

  if (isSubmitted) {
    return (
      <div className="thank-you-message">
        <h2>Danke für deine Registration. Du wirst in Kürze per SMS von uns hören.</h2>
        <p>{apiResponse}</p>
      </div>
    );
  }

  if (!isEligible) {
    return (
      <div className="thank-you-message">
        <h2>Vielen Dank für Ihr Interesse an der Studie!</h2>
        <p>{apiResponse}</p>
      </div>
    );
  }

  if (!showSignUp) {
    return (
      <div className="info-container">
        <h1>Herzlich Willkommen zur TAP-Pilotstudie</h1>
        <h4>Informationen zur Studie</h4>
        <p>
        Das Ziel der Studie ist es, den Alltag sowie die Freizeitgestaltung von Jugendlichen und jungen Erwachsenen besser zu verstehen. 
        Dazu möchten wir euch gerne über die nächsten Tage begleiten und euch bitten, die in unregelmässigen Abständen gesendeten, kurzen Umfragen zu beantworten. Hierzu werdet ihr im Falle einer Teilnahme gebeten, euer Alter und eure Mobiltelefonnummer anzugeben. Über diese werden wir euch mehrere Male in der nächsten Woche kontaktieren und euch einen Link zur aktuellen Befragung zukommen lassen. Sämtliche Kontaktdaten werden vertraulich behandelt und nach Ende der Erhebung vollständig gelöscht <a href='https://tap-study.ch/privacy' target='_blank'>(siehe Informationen zum Datenschutz)</a>.
        Es wer den insgesamt 16 kurze Befragungen über eine Dauer von vier Tagen stattfinden. Zu Beginn findet eine Eingangserhebung statt.
        </p>
        <ul>
          <li>
          <p>In der Eingangserhebung geht es um Ihr Erleben und Verhalten im Allgemeinen und Ihre aktuelle Lebenssituation. Wenn du dich bereit erklärst, an der Studie teilzunehmen, erhältst du per SMS einen Link zur Eingangserhebung (ca. 45 Minuten) mit der Bitte, diese innerhalb von 24 Stunden auszufüllen.</p>
          </li>
          <li>
            <p>In den 16 kurzen Befragungen geht es um die  aktuelle Situation, in der du dich gerade befindest. Diese beginnen einen Tag nach der Eingangserhebung. Dazu würden wir dich bitten, an 4 zufälligen Tagen innerhalb dieser Woche 4 mal pro Tag an einer kurzen Umfrage teilzunehmen (je ca. 2 Minuten). Zu diesem Zweck würden wir dir zu zufälligen Zeitpunkten (ausgenommen von dir bei der Eingangserhebung geblockte Zeiten) per SMS einen Link zur Umfrage zusenden. Nach Erhalt des Links sollte die Umfrage direkt ausgefüllt werden oder zumindest innerhalb von 10 Minuten.</p>
          </li>
        </ul> 

        <h4>Warum solltest du teilnehmen?</h4>
        <ul>
          <li>Durch deine Teilnahme leistest du einen wichtigen Beitrag zur Erforschung des Alltags von Jugendlichen und jungen Erwachsenen. </li>
          <li>Um dir für die Beendigung der kompletten Studie zu danken, kannst du einen Einkaufsgutschein im Wert von 10 CHF/EUR erhalten. Alternativ kannst du dich anstelle des Gutscheins auch dazu entscheiden, dass wir einen Baum pflanzen werden.</li>
        </ul>

        <h4>Teilnahmevoraussetzungen</h4>
        <ul>
          <li>zwischen 18 und 25 Jahre alt</li>
          <li>gute Deutschkenntnisse</li>
          <li>Smartphone mit Internetverbindung</li>
        </ul>
        
        <h4>Wichtige Informationen</h4>
        <ul>
          <li>In dieser Studie gibt es keine "richtigen" oder "falschen" Antworten.</li>
          <li>Die Teilnahme an der Studie ist freiwillig und du hast das Recht, jederzeit und ohne Angabe von Gründen die Zustimmung zur Teilnahme zu widerrufen, ohne dass dir dadurch Nachteile entstehen. Schreibe uns dazu eine E-Mail (TAP-study.psychologie@unibas.ch).</li>
          <li>Die Studienteilnahme ist mit keinerlei gesundheitlichen Risiken verbunden.</li>
        </ul>

        <h4>Informationen zum Datenschutz</h4>
        <ul>
          <li>Deine Daten werden ausschliesslich für Forschungszwecke verwendet. Wir weisen dich darauf hin, dass Ihre Angaben für die Dauer der Studie nicht komplett anonym erhoben werden können – die Mobiltelefonnummer wird für die Zustellung des Befragungslinks verwendet. Deine Mobiltelefonnummer wird jedoch getrennt von den Daten gespeichert, vertraulich behandelt, nicht weitergegeben und nach Abschluss der Studie gelöscht - anschliessend sind alle Daten komplett anonym. Detaillierte Informationen zum Datenschutz findest du <a target="_blank" href='/privacy'>hier</a>.</li>
          <li>Im Einklang mit den Prinzipien von <a target="_blank" href='http://www.snf.ch/de/fokusForschung/themendossiers/open-science/Seiten/default.aspx'>Open Science</a> können bei der Publikation der Studie die <u>anonymisierten Daten der aktuellen Befragung</u> mittels einer entsprechenden wissenschaftlichen Online-Plattform zur Verfügung gestellt werden. Dies geschieht im Einklang mit den geltenden Datenschutzrichtlinien und deine Antworten können <u>nicht</u> mit deiner Person in Verbindung gebracht werden.</li>
        </ul>

        <p> 
        Wir würden uns sehr über deine Teilnahme freuen!<br></br>
        Bei Fragen kontaktiere uns unter:<br></br>
        TAP-study.psychologie@unibas.ch
        </p>

        <p>
        Ich habe die aufgeführten Bedingungen gelesen und verstanden. Mit Anklicken des Zustimmungs-Felds bestätige ich mein Einverständnis zur Teilnahme an dieser Studie.
        </p>

        <button
          className="agree-button"
          onClick={handleAgree}
          >
          Ich stimme zu
        </button>
      </div>
    );
  }

  if (smsSent) {
    return (
      <div className="verification-container">
        <h2>Bitte geben Sie den per SMS gesendeten Code ein</h2>
        <form onSubmit={handleVerification}>
          <div className="form-group">
            <label>
              Bestätigungscode:
              <input
                type="number"
                name="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
              />
            </label>
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          <button className="submit-button" type="submit">Code bestätigen</button>
        </form>
      </div>
    );
  }

  return (
    <div className="form-container">
      <h2 className="form-title">Anmeldung zur Personen-Zeit-Verhalten-Studie</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            Alter:
            <input
              type="number"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div className="form-group">
          <label>
            Telefon Nummer:
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handlePhoneNumberChange}
              required
              placeholder="+41797005846"
            />
          </label>
        </div>
        <div className="form-group">
          <label>Region:</label>
          <div>
            <label>
              <input
                type="radio"
                name="region"
                value="basel"
                checked={formData.region === 'basel'}
                onChange={handleRegionChange}
                required
              />
              Ich wohne in der Region Basel
            </label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                name="region"
                value="nicht-basel"
                checked={formData.region === 'nicht-basel'}
                onChange={handleRegionChange}
                required
              />
              Ich wohne ausserhalb der Region Basel
            </label>
          </div>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {/*<div className="form-group checkbox-group">
          <label>
            <input
              type="checkbox"
              name="agreedToTerms"
              checked={formData.agreedToTerms}
              onChange={handleChange}
              required
            />
            Ich stimme der&nbsp;
            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
              Datenschutzerklärung
            </a>
            &nbsp;zu.
          </label>
        </div>*/}
        <button className="submit-button" type="submit">Registrieren</button>
      </form>
    </div>
  );
};

export default FormComponent;
